import React from 'react';

const Track = ({ trackmap, trackname, layout, length, turns, children }) => {
  // const { trackmap, trackname, layout, length, turns, children } = props;
  return (
    <article className='track'>
      <img src={trackmap}></img>
      <h1>{trackname}</h1>
      <h4>{layout}</h4>
      <p>{length}</p>
      <p>{turns}</p>
      {children}
    </article>
  );
};

export default Track;
