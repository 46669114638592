import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { tracks } from './tracks';
import Track from './Track';

function TrackList() {
  return (
    <section className='tracklist'>
      {tracks.map((track) => {
        return <Track key={track.id} {...track}></Track>;
      })}
    </section>
  );
}

ReactDOM.render(<TrackList />, document.getElementById('root'));
