export const tracks = [
  {
    id: 1,
    trackname: 'Watkins Glen International',
    layout: 'Grand Prix Course',
    length: '3.4 miles',
    turns: '11 turns',
    trackmap:
      'https://www.imsa.com/wp-content/uploads/sites/32/2019/09/WGI_1346x916-350x238.png',
    // 'https://www.theglen.com/wp-content/uploads/sites/1009/2022/03/22/IMSA-MAP.jpg',
  },
  {
    id: 2,
    trackname: 'WeatherTech Raceway Laguna Seca',
    layout: 'Road Course',
    length: '2.238 miles',
    turns: '11 turns',
    trackmap:
      'https://www.imsa.com/wp-content/uploads/sites/32/2019/09/MRLS_1346x916-350x238.png',
    // 'https://racer.com/wp-content/uploads/sites/85/2018/04/laguna_seca_map_no_logosg.jpg',
  },
  {
    id: 3,
    trackname: 'VIRginia International Raceway',
    layout: 'Full Course',
    length: '3.27 miles',
    turns: '18 turns',
    trackmap:
      'https://www.imsa.com/wp-content/uploads/sites/32/2019/09/VIR_1346x916-350x238.png',
  },
  {
    id: 4,
    trackname: 'Michelin Raceway Road Atlanta',
    layout: 'Grand Prix Circuit',
    length: '2.54 miles',
    turns: '12 turns',
    trackmap:
      'https://www.imsa.com/wp-content/uploads/sites/32/2019/09/RoadAtlanta_1346x916-350x238.png',
  },
  {
    id: 5,
    trackname: 'Road America',
    layout: 'Grand Prix Course',
    length: '4.0 miles',
    turns: '14 turns',
    trackmap:
      'https://www.imsa.com/wp-content/uploads/sites/32/2019/09/RoadAmerica_1346x916-350x238.png',
  },
  {
    id: 6,
    trackname: 'Lime Rock Park',
    layout: '?',
    length: '1.5 miles',
    turns: '7 turns',
    trackmap:
      'https://www.imsa.com/wp-content/uploads/sites/32/2019/11/limerock_988x480.png',
  },
  {
    id: 7,
    trackname: 'Canadian Tire Motorsport Park',
    layout: '?',
    length: '2.459 miles',
    turns: '10 turns',
    trackmap:
      'https://www.imsa.com/wp-content/uploads/sites/32/2019/09/CTMP_1346x916-350x238.png',
  },
  {
    id: 8,
    trackname: 'Raceway at Belle Isle Park',
    layout: '?',
    length: '2.35 miles',
    turns: '14 turns',
    trackmap:
      'https://www.imsa.com/wp-content/uploads/sites/32/2019/09/DETROIT_1346x916-350x238.png',
  },
  {
    id: 9,
    trackname: 'Mid-Ohio Sports Car Course',
    layout: '?',
    length: '2.258 miles',
    turns: '13 turns',
    trackmap:
      'https://www.imsa.com/wp-content/uploads/sites/32/2019/09/3D-Mid-Ohio-Vector-350x192.png',
  },
  {
    id: 10,
    trackname: 'Long Beach Street Circuit',
    layout: '?',
    length: '1.968 miles',
    turns: '11 turns',
    trackmap:
      'https://www.imsa.com/wp-content/uploads/sites/32/2019/09/LongBeach_1346x916-350x238.png',
  },
  {
    id: 11,
    trackname: 'Sebring International Raceway',
    layout: '?',
    length: '3.74 miles',
    turns: '17 turns',
    trackmap:
      'https://www.imsa.com/wp-content/uploads/sites/32/2019/09/SEBRING_1346x916-350x238.png',
  },
  {
    id: 12,
    trackname: 'Daytona International Speedway',
    layout: '?',
    length: '3.56 miles',
    turns: '12 turns',
    trackmap:
      'https://www.imsa.com/wp-content/uploads/sites/32/2019/09/DIS_1346x916-1-350x238.png',
  },
  {
    id: 13,
    trackname: 'VIRginia International Raceway',
    layout: 'Grand Course',
    length: '4.10 miles',
    turns: '24 turns',
    trackmap:
      'https://virnow.com/wp-content/uploads/2014/05/VIR_Grand-Course_2018-1-scaled.jpg',
  },
  {
    id: 14,
    trackname: 'VIRginia International Raceway',
    layout: 'North Course',
    length: '2.25 miles',
    turns: '14 turns',
    trackmap:
      'https://virnow.com/wp-content/uploads/2017/11/VIR_North-Course_2018-scaled.jpg',
  },
  {
    id: 15,
    trackname: 'VIRginia International Raceway',
    layout: 'South Course',
    length: '1.65 miles',
    turns: '9 turns',
    trackmap:
      'https://virnow.com/wp-content/uploads/2017/11/VIR_South-Course_2018-scaled.jpg',
  },
  {
    id: 16,
    trackname: 'VIRginia International Raceway',
    layout: 'Patriot Course',
    length: '1.10  miles',
    turns: '9 turns',
    trackmap:
      'https://virnow.com/wp-content/uploads/2017/11/VIR_South-Course_2018-scaled.jpg',
  },
  {
    id: 17,
    trackname: 'Road Atlanta',
    layout: 'Shout Circuit',
    length: '?',
    turns: '9 turns?',
    trackmap:
      'https://www.racingcircuits.info/assets/images/maps/North%20America/USA/Road%20Atlanta/RoadAtlanta2017Short.png',
  },
  {
    id: 18,
    trackname: 'Road Atlanta',
    layout: 'Club Circuit',
    length: '?',
    turns: '4 turns?',
    trackmap:
      'https://www.racingcircuits.info/assets/images/maps/North%20America/USA/Road%20Atlanta/RoadAtlanta2017Club.png',
  },
  // {
  //   id: 19,
  //   trackname: '',
  //   layout: '',
  //   length: ' miles',
  //   turns: ' turns',
  //   trackmap: '',
  // },
];
